import {FC, useState, useEffect, useRef} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {useAuth} from '../../../../_metronic/store/auth'
import {getUserByToken} from './_requests'

const AuthInit: FC = ({children}) => {
  const {accessToken, logout, setUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data: user} = await getUserByToken()
          if (!!user?.data) {
            setUser(user?.data)
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (!!accessToken) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit}
