import {
  APIBaseResponse,
  APIPaginationResponse,
} from '../../../../../_metronic/helpers/interface/api'
import {API_MISC_SERVICE} from '../../../../../_metronic/helpers/utils/constants'
import {httpRequest} from '../../../../../_metronic/helpers/utils/httpRequest'
import {BranchDataModel, BranchParams, BranchPayload, ICoveragePayload} from '../interface'

const miscService = httpRequest(API_MISC_SERVICE!)

export async function getAllBranches(params: BranchParams) {
  const response = await miscService.get<APIPaginationResponse<BranchDataModel[]>>('/branch', {
    params,
  })
  return response.data
}

export async function getBranchById(id: number) {
  const response = await miscService.get<APIBaseResponse<BranchDataModel>>(`/branch/${id}`)
  return response.data
}

export async function uploadFile(body: FormData) {
  const response = await miscService.post('/images', body)
  return response.data
}

export async function createBranch(body: BranchPayload) {
  const response = await miscService.post('/branch', body)
  return response.data
}
export async function postVillageCoverages(id: string, body: ICoveragePayload) {
  const response = await miscService.post(`/branch/${id}/coverage`, body)
  return response.data
}

export async function updateBranchById(data: {id: number; body: BranchPayload}) {
  const response = await miscService.put(`/branch/${data.id}`, data.body)
  return response.data
}
export async function DeleteBranch(id: number) {
  const response = await miscService.delete('/branch/' + id.toString())
  return response.data
}
