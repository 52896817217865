import {useMutation, useQuery} from '@tanstack/react-query'
import {BranchParams} from '../interface'
import {DeleteBranch, getAllBranches, getBranchById} from './api'

export function useGetAllBranches(params: BranchParams, enabled = true) {
  return useQuery(['get-all-branches', {params}], () => getAllBranches(params), {enabled})
}

export function useGetBranchById(id: number, enabled = true) {
  return useQuery(['get-branch-by-id', {id}], () => getBranchById(id), {enabled})
}
export function useDeleteBranch() {
  return useMutation(['delete-branch'], (id: number) => DeleteBranch(id))
}
