import React from 'react'
import {KTSVG} from './KTSVG'

interface IProps {
  type: 'info' | 'error'
  title: string
  description: string
  onHide: () => void
}

const Toast: React.FC<IProps> = ({type, title, description, onHide}) => {
  return (
    <div
      className={`d-flex flex-column flex-sm-row p-5 position-fixed fixed-top w-100 app-toast ${
        type === 'error' ? 'bg-light-danger' : 'bg-light-primary'
      }`}
      style={{zIndex: 999999}}
    >
      <div
        className={`d-flex flex-column pe-0 pe-sm-10 ${
          type === 'error' ? 'text-danger' : 'text-primary'
        }`}
      >
        <h5 className='mb-2'>{title}</h5>
        <pre className='text-wrap'>{description}</pre>
      </div>

      <button
        onClick={onHide}
        type='button'
        className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </button>
    </div>
  )
}

export default Toast
