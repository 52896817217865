import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const LocationsPage = lazy(() => import('../modules/apps/locations'))
  const BranchManagementPage = lazy(() => import('../modules/apps/branches'))
  const ProductManagementPage = lazy(() => import('../modules/apps/products'))
  const ProductDetailPage = lazy(() => import('../modules/apps/products/ProductDetail'))
  const DayoffPage = lazy(() => import('../modules/apps/day-offs'))
  const PatientManagementPage = lazy(() => import('../modules/apps/patient'))
  const RequirementManagementPage = lazy(() => import('../modules/apps/checkup-requirements'))
  const CheckupMonitorPage = lazy(() => import('../modules/apps/checkups/monitor'))
  const ManagementCategoryPage = lazy(() => import('../modules/apps/categories'))
  const CheckupListPage = lazy(() => import('../modules/apps/checkups/list'))
  const CheckupScanPage = lazy(() => import('../modules/apps/checkups/scan'))
  const CheckupDetailPage = lazy(() => import('../modules/apps/checkups/detail'))
  const ScheduleHomecare = lazy(() => import('../modules/apps/schedule-homecare/'))
  const VoucherManagementPage = lazy(() => import('../modules/apps/vouchers'))
  const PromotionManagementPage = lazy(() => import('../modules/apps/promotions'))
  const ManagementPage = lazy(() => import('../modules/apps/pages'))
  const ManagementRole = lazy(() => import('../modules/apps/roles'))
  const ManagementUserPage = lazy(() => import('../modules/apps/users'))
  const SettingsManagement = lazy(() => import('../modules/apps/settings'))
  const ActivityLogPage = lazy(() => import('../modules/apps/activity-log'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/location-management'
          element={
            <SuspensedView>
              <LocationsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/branch-management/*'
          element={
            <SuspensedView>
              <BranchManagementPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/day-offs/*'
          element={
            <SuspensedView>
              <DayoffPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/products/*'
          element={
            <SuspensedView>
              <ProductManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/products/detail/*'
          element={
            <SuspensedView>
              <ProductDetailPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/requirement-management/*'
          element={
            <SuspensedView>
              <RequirementManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/category-management/*'
          element={
            <SuspensedView>
              <ManagementCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/checkups/monitor/*'
          element={
            <SuspensedView>
              <CheckupMonitorPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/checkups/list/*'
          element={
            <SuspensedView>
              <CheckupListPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/checkups/detail/*'
          element={
            <SuspensedView>
              <CheckupDetailPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/checkups/scan'
          element={
            <SuspensedView>
              <CheckupScanPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/vouchers/*'
          element={
            <SuspensedView>
              <VoucherManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/manage-promotion/*'
          element={
            <SuspensedView>
              <PromotionManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/patient-management/*'
          element={
            <SuspensedView>
              <PatientManagementPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/schedule-homecare/*'
          element={
            <SuspensedView>
              <ScheduleHomecare />
            </SuspensedView>
          }
        />

        <Route
          path='apps/pages/*'
          element={
            <SuspensedView>
              <ManagementPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/users/*'
          element={
            <SuspensedView>
              <ManagementUserPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/roles/*'
          element={
            <SuspensedView>
              <ManagementRole />
            </SuspensedView>
          }
        />

        <Route
          path='apps/settings/*'
          element={
            <SuspensedView>
              <SettingsManagement />
            </SuspensedView>
          }
        />

        <Route
          path='apps/activity-log/*'
          element={
            <SuspensedView>
              <ActivityLogPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
