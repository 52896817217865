export const APP_NAME = process.env.REACT_APP_NAME
export const API_USER_SERVICE = process.env.REACT_APP_USER_SERVICE_URL
export const API_MISC_SERVICE = process.env.REACT_APP_MISC_SERVICE_URL
export const API_PRODUCT_SERVICE = process.env.REACT_APP_PRODUCT_SERVICE_URL
export const API_ORDER_SERVICE = process.env.REACT_APP_ORDER_SERVICE_URL
export const API_ENCRYPT_SERVICE = process.env.REACT_APP_ENCRYPT_URL

export const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB
export const SUPPORTED_IMAGE_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/svg+xml',
]

export const MENU = {
  upload: {
    image: 'upload.image',
    document: 'upload.document',
    video: 'upload.video',
    any: 'upload.any',
  },
  role: {
    create: 'role.create',
    view: 'role.view',
    update: 'role.update',
    delete: 'role.delete',
  },
  user: {
    create: 'user.create',
    view: 'user.view',
    update: 'user.update',
    delete: 'user.delete',
    resetPassword: 'user.superadmin-reset-password',
  },
  admin: {
    create: 'admin.create',
    view: 'admin.view',
    update: 'admin.update',
    delete: 'admin.delete',
  },
  patient: {
    create: 'patient.create',
    view: 'patient.view',
    update: 'patient.update',
    delete: 'patient.delete',
  },
  branch: {
    create: 'branch.create',
    view: 'branch.view',
    update: 'branch.update',
    delete: 'branch.delete',
  },
  product: {
    create: 'product.create',
    view: 'product.view',
    update: 'product.update',
    delete: 'product.delete',
  },
  productPrice: {
    create: 'productprice.create',
    view: 'productprice.view',
    update: 'productprice.update',
    delete: 'productprice.delete',
  },
  voucher: {
    create: 'voucher.create',
    view: 'voucher.view',
    update: 'voucher.update',
    delete: 'voucher.delete',
  },
  masterdata: {
    create: 'masterdata.create',
    view: 'masterdata.view',
    update: 'masterdata.update',
    delete: 'masterdata.delete',
  },
  checkup: {
    create: 'checkup.create',
    view: 'checkup.view',
    update: 'checkup.update',
    delete: 'checkup.delete',
  },
  requirement: {
    create: 'requirement.create',
    view: 'requirement.view',
    update: 'requirement.update',
    delete: 'requirement.delete',
  },
  page: {
    create: 'page.create',
    view: 'page.view',
    update: 'page.update',
    delete: 'page.delete',
  },
  promo: {
    create: 'promo.create',
    view: 'promo.view',
    update: 'promo.update',
    delete: 'promo.delete',
  },
  banner: {
    create: 'banner.create',
    view: 'banner.view',
    update: 'banner.update',
    delete: 'banner.delete',
  },
  pusherToken: {
    create: 'pusher-token.create',
    view: 'pusher-token.view',
    update: 'pusher-token.update',
    delete: 'pusher-token.delete',
  },
  notification: {
    create: 'notification.create',
    view: 'notification.view',
    update: 'notification.update',
    delete: 'notification.delete',
  },
  homecare: {
    create: 'branch-operational-block.create',
    view: 'branch-operational-block.view',
    update: 'branch-operational-block.update',
    delete: 'branch-operational-block.delete',
  },
}

export const daysShort = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
export const engIndDays = {
  mon: 'Senin',
  tue: 'Selasa',
  wed: 'Rabu',
  thu: 'Kamis',
  fri: 'Jumat',
  sat: 'Sabtu',
  sun: 'Minggu',
}

export const pageContentObj = {
  'access-location': 'Halaman Register',
  'select-service-notes': 'Form Homecare Service',
  'doctor-notes': 'Form Service Tipe APD',
  'terms-and-conditions-examination': 'Halaman Ringkasan Pesanan',
  'privacy-policy': 'Halaman Register',
  'terms-and-conditions': 'Halaman Register',
}
