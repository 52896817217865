/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useGetBranchById} from '../../../../app/modules/apps/branches/services/query'
import {getGravatarURL, hasPermission} from '../../../helpers/utils/common'
import {useAuth} from '../../../store/auth'
import {ROLE_ENUM} from '../../../helpers/utils/constants/role'

const HeaderUserMenu: FC = () => {
  const {logout, user} = useAuth()

  const userPermissions = user?.role?.permissions ?? []
  const hasBranchViewPermission = hasPermission(ROLE_ENUM.BRANCH_VIEW, userPermissions)

  const branchId = user?.admin?.branchId ?? 0
  const {data} = useGetBranchById(branchId, !!branchId && hasBranchViewPermission)
  const branch = data?.data

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-400px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={getGravatarURL(user?.email)} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{user?.admin?.name}</div>
          </div>
        </div>
      </div>
      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5 justify-content-between'>
          <span>Role</span>
          <span className='menu-text'>{user?.role?.displayName ?? '-'}</span>
        </a>
        <a href='#' className='menu-link px-5 justify-content-between'>
          <span>Cabang</span>
          <span className='menu-text'>{branch?.name ?? '-'}</span>
        </a>
        <a href='#' className='menu-link px-5 justify-content-between'>
          <span>Email</span>
          <span className='menu-text'>{user?.email ?? '-'}</span>
        </a>
      </div>
      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
