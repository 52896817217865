/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {AxiosError} from 'axios'
import {add} from 'date-fns'
import {useLocation} from 'react-router-dom'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../../../../_metronic/store/auth'
import {APP_NAME} from '../../../../_metronic/helpers/utils/constants'
import {useToast} from '../../../../_metronic/context/toast'

function calculateExpiredTokenTime(expiresIn: string) {
  const [seconds] = expiresIn.split('s')
  const now = new Date()
  return add(now, {seconds: isNaN(Number(seconds)) ? 0 : Number(seconds)})
}

const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email wajib diisi.'),
  password: Yup.string().required('Password wajib diisi.'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {setToken, setUser} = useAuth()
  const toast = useToast()
  const location = useLocation()
  const locationState = location.state as any
  const isExpiredSession = locationState?.loginPage === 'session-expired'

  useEffect(() => {
    if (isExpiredSession) {
      toast.addToast('info', 'info', 'Session telah habis, silahkan login kembali')
    }
  }, [locationState?.loginPage])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login({
          username: values.email,
          password: values.password,
          role: 'admin',
        })
        const expiredAt = calculateExpiredTokenTime(auth.data.expiresIn)
        setToken({
          token: auth?.data?.accessToken ?? '',
          expiredAt,
        })
        const {data: user} = await getUserByToken()
        setUser(user?.data)
      } catch (error) {
        const errorResponse = error as AxiosError<{success: boolean; message: string}>
        setUser(null)
        setToken({token: null, expiredAt: null})
        toast.addToast(
          'error',
          'Gagal',
          errorResponse?.response?.data?.message ?? 'Email dan password tidak valid.'
        )
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to {APP_NAME}</h1>
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
      </div>
      {/* end::Action */}
    </form>
  )
}
