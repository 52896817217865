import axios from 'axios'
import {useAuth} from '../../store/auth'
const {getState} = useAuth

export const httpRequest = (baseURL: string) => {
  const instance = axios.create({baseURL})

  instance.interceptors.request.use(
    (request) => {
      const accessToken = getState()?.accessToken
      request.headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      return request
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (err) => {
      const status = err.response.status

      if (status === 403) {
        window.location.href = '/error/403'
      }
      return Promise.reject(err)
    }
  )

  return instance
}
