import {APIBaseResponse} from '../../../../_metronic/helpers/interface/api'
import {API_USER_SERVICE} from '../../../../_metronic/helpers/utils/constants'
import {httpRequest} from '../../../../_metronic/helpers/utils/httpRequest'
import {ILoginPayload, IUserModel, IUserTokenModel} from './_models'

const userService = httpRequest(API_USER_SERVICE!)

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_USER_SERVICE}/verify_token`
export const LOGIN_URL = `${API_USER_SERVICE}/auth/login`
export const REGISTER_URL = `${API_USER_SERVICE}/register`
export const REQUEST_PASSWORD_URL = `${API_USER_SERVICE}/forgot_password`

// Server should return AuthModel
export function login(data: ILoginPayload) {
  return userService.post<APIBaseResponse<IUserTokenModel>>('/auth/login', data)
}

export function getUserByToken() {
  return userService.get<APIBaseResponse<IUserModel>>('/auth/me')
}
