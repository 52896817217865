import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../_metronic/store/auth'

const Error403: FC = () => {
  const {logout} = useAuth()
  const navigate = useNavigate()

  const handleToLogin = () => {
    logout()
    navigate('/auth/login')
  }

  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
        Anda tidak memiliki akses ke halaman.{' '}
      </h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>Silahkan hubungi superadmin.</div>
      <div className='text-center'>
        <button type='button' onClick={handleToLogin} className='btn btn-lg btn-primary fw-bolder'>
          Go login page
        </button>
      </div>
    </>
  )
}

export {Error403}
