import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {isAfter} from 'date-fns'
import {useAuth} from '../../../_metronic/store/auth'

export function useExpiredToken() {
  const location = useLocation()
  const navigate = useNavigate()
  const {expiredAt, logout} = useAuth()

  useEffect(() => {
    if (!expiredAt) return
    const now = new Date()
    const expiredTime = new Date(expiredAt)
    const isAlreadyExpired = isAfter(now, expiredTime)
    if (isAlreadyExpired) {
      logout()
      navigate('/auth?state=session-expired', {
        state: {
          loginPage: 'session-expired',
        },
      })
    }
  }, [location, expiredAt])
}
