import {PageTitle} from '../../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const DashboardPage = () => (
  <>
    <div className='row g-5 gx-xxl-8' style={{height: '100%'}}>
      <div className='p-4 pt-20 h-full' style={{height: '100%'}}>
        <div>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/biomedilab-logo.png')}
            className='h-50px mb-5 text-center d-block mx-auto'
          />
          <h1 className='fw-bolder fs-2x text-center text-gray-700 mb-10'>
            Selamat datang di aplikasi admin Biomedilab.
          </h1>
        </div>
      </div>
    </div>
  </>
)

const DashboardWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}

export default DashboardPage
