import {add} from 'date-fns'
import format from 'date-fns/format'
import id from 'date-fns/locale/id'
import md5 from 'md5'
import * as DOMPurify from 'dompurify'
import {IPermissionModel} from '../../../app/modules/auth'

export function isValidDate(value: Date | string) {
  const dateInput = value instanceof Date ? value : new Date(value)
  return !isNaN(dateInput.getDate())
}

export function formatDate(value?: Date | string | null, strFormat = 'dd MMMM yyyy', locale = id) {
  if (!value || !isValidDate(value)) return ''
  const inputValue = value instanceof Date ? value : new Date(value)
  return format(inputValue, strFormat, {locale})
}

export function formatPrice(price: number) {
  const formatedPrice = new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(
    price
  )
  return formatedPrice
}
export function priceToNumber(price: string) {
  price = price.replace('Rp', '').replace('.', '').replace(',', '.')
  return Number(price)
}

export function fileBase64(file: File) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export function fileDownloader(blobParts: BlobPart, name: string) {
  const a = document.createElement('a')
  const blob = new Blob([blobParts], {type: 'application/csv'})
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export function translateGender(gender: string) {
  if (gender) {
    return gender === 'Male' ? 'Laki-laki' : 'Perempuan'
  }
  return '-'
}

export function translateStatus(status: string): string {
  switch (status) {
    case 'confirmation':
      return 'Menunggu Konfirmasi'
    case 'scheduled':
      return 'Menunggu Kedatangan'
    case 'process':
    case 'processed':
      return 'Diproses'
    case 'done':
      return 'Hasil Tersedia'
    case 'cancel':
      return 'Batal'
    default:
      return '-'
  }
}
export function translateStatusPayment(status: string): string {
  switch (status) {
    case 'paid':
      return 'Lunas'
    case 'pending':
      return 'Belum Lunas'
    case 'refund':
      return 'Pengembalian'
    case 'cancelled':
      return 'Di Batalkan'
    default:
      return '-'
  }
}

export function transformStatus(active: boolean): string {
  return active ? 'Aktif' : 'Non-aktif'
}

export function getGravatarURL(email: string | undefined) {
  const address = String(email).trim().toLowerCase()
  const hash = md5(address)
  return `https://www.gravatar.com/avatar/${hash}`
}

export function createDateArray(start: Date, end: Date) {
  const result: Date[] = []
  let now = start
  while (now <= end) {
    result.push(now)
    now = add(now, {days: 1})
  }

  return result
}

export function formatTime(time: string) {
  // format from hh:mm:ss to hh:mm
  const arrayTime = time.split(':')
  return arrayTime.slice(0, 2).join(':')
}

export function hasPermission(permissionName: string, permissions: IPermissionModel[]) {
  return permissions.find((permission) => permission.name === permissionName) !== undefined
}

export function removeNullParams(params: any) {
  const tempParams = params
  Object.keys(tempParams).forEach((key) => {
    if (tempParams[key]?.length === 0 || !tempParams[key]) {
      delete tempParams[key]
    }
  })

  return tempParams
}

export function createCleanHtml(dirtyHtml: string) {
  if (!dirtyHtml?.length) return ''
  const cleanHtml = DOMPurify.sanitize(dirtyHtml)
  return cleanHtml
}
