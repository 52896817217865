import {createContext, useContext, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import Toast from '../helpers/components/Toast'

interface Props {
  children: React.ReactNode
}

interface ToastData {
  type: 'info' | 'error'
  title: string
  description: string
}

interface IContext {
  addToast: (type: 'info' | 'error', title?: string, description?: string) => void
}

const ToastContext = createContext<IContext>({
  addToast: () => {},
})

export const ToastProvider: React.FC<Props> = ({children}) => {
  const [show, setShow] = useState(false)
  const [data, setData] = useState<ToastData>({
    type: 'info',
    title: '',
    description: '',
  })
  const toastPortal = document.querySelector('#root-toast')

  useEffect(() => {
    let timer: any
    if (show) {
      timer = setTimeout(hideToast, 5000)
    }

    return () => clearTimeout(timer)
  }, [show])

  const addToast = (type: 'info' | 'error', title?: string, description?: string) => {
    setShow(true)
    setData({
      type,
      title: title || '',
      description: description || '',
    })
  }

  const hideToast = () => {
    setShow(false)
    setData({
      type: 'info',
      title: '',
      description: '',
    })
  }

  return (
    <ToastContext.Provider value={{addToast}}>
      {show &&
        createPortal(
          <Toast
            type={data.type}
            title={data.title}
            description={data.description}
            onHide={hideToast}
          />,
          toastPortal ?? document?.body
        )}
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  return useContext(ToastContext)
}
