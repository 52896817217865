import {create, StateCreator} from 'zustand'
import {persist, PersistOptions} from 'zustand/middleware'

import {IUserModel} from '../../app/modules/auth'

interface AuthState {
  accessToken: string | null
  expiredAt: Date | null
  user: IUserModel | null
  setToken: ({token, expiredAt}: {token: string | null; expiredAt: Date | null}) => void
  setUser: (user: IUserModel | null) => void
  logout: () => void
}

type MyPersist = (
  config: StateCreator<AuthState>,
  options: PersistOptions<AuthState>
) => StateCreator<AuthState>

export const useAuth = create<AuthState>(
  (persist as MyPersist)(
    (set) => ({
      accessToken: null,
      user: null,
      expiredAt: null,
      setToken: ({token, expiredAt}) => {
        set(() => ({
          accessToken: token,
          expiredAt,
        }))
      },
      setUser: (user: IUserModel | null) => {
        set(() => ({
          user: user,
        }))
      },
      logout: () => {
        set(() => ({
          user: null,
          accessToken: null,
          expiredAt: null,
        }))
      },
    }),
    {name: 'auth-store'}
  )
)
