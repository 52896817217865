/* eslint-disable react/jsx-no-target-blank */
import {useAuth} from '../../../store/auth'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {hasPermission} from '../../../helpers/utils/common'
import {ROLE_ENUM} from '../../../helpers/utils/constants/role'

export function AsideMenuMain() {
  const {user} = useAuth()
  const isSuperAdmin = user?.role?.displayName?.toLocaleLowerCase() === 'superadmin'
  const userPermissions = user?.role?.permissions ?? []

  return (
    <>
      <div className='menu-item fs-4'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Management</span>
        </div>
      </div>

      <AsideMenuItemWithSub to='/apps/checkup' title='Pemeriksaan' hasBullet>
        {hasPermission(ROLE_ENUM.CHECKUP_MONITOR_VIEW, userPermissions) && (
          <AsideMenuItem
            to='/apps/checkups/monitor?status=confirmation'
            icon='/media/icons/duotune/electronics/elc004.svg'
            title='Monitor Pemeriksaan'
            fontIcon='bi-layers'
          />
        )}
        {hasPermission(ROLE_ENUM.CHECKUP_DATA_VIEW, userPermissions) && (
          <AsideMenuItem
            to='/apps/checkups/list'
            icon='/media/icons/duotune/general/gen005.svg'
            title='Data Pemeriksaan'
            fontIcon='bi-layers'
          />
        )}
        {hasPermission(ROLE_ENUM.SCAN_CHECKUP_QR, userPermissions) && (
          <AsideMenuItem
            to='/apps/checkups/scan'
            icon='/media/icons/duotune/art/art006.svg'
            title='Scan QR Pemeriksaan'
            fontIcon='bi-layers'
          />
        )}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/apps/master' title='Master Data' hasBullet>
        {hasPermission(ROLE_ENUM.CATEGORY_VIEW, userPermissions) && (
          <AsideMenuItem
            to='/apps/category-management'
            icon='/media/icons/duotune/ecommerce/ecm009.svg'
            title='Manage Kategori Produk'
            fontIcon='bi-layers'
          />
        )}
        {hasPermission(ROLE_ENUM.REQUIREMENT_VIEW, userPermissions) && (
          <AsideMenuItem
            to='/apps/requirement-management'
            icon='/media/icons/duotune/medicine/med001.svg'
            title='Manage Persyaratan Pemeriksaan'
            fontIcon='bi-layers'
          />
        )}
        {hasPermission(ROLE_ENUM.BRANCH_VIEW, userPermissions) && (
          <AsideMenuItem
            to='/apps/branch-management'
            icon='/media/icons/duotune/ecommerce/ecm004.svg'
            title='Manage Cabang'
            fontIcon='bi-layers'
          />
        )}
        {hasPermission(ROLE_ENUM.PAGE_VIEW, userPermissions) && (
          <AsideMenuItem
            to='/apps/pages'
            icon='/media/icons/duotune/files/fil001.svg'
            title='Manage Page'
            fontIcon='bi-layers'
          />
        )}
        {hasPermission(ROLE_ENUM.DAY_OFF_VIEW, userPermissions) && (
          <AsideMenuItem
            to='/apps/day-offs'
            icon='/media/icons/duotune/general/gen014.svg'
            title='Manage Hari Libur'
            fontIcon='bi-layers'
          />
        )}
        {hasPermission(ROLE_ENUM.MASTER_LOCATION_VIEW, userPermissions) && (
          <AsideMenuItem
            to='/apps/location-management'
            icon='/media/icons/duotune/maps/map002.svg'
            title='Manage Lokasi'
            fontIcon='bi-layers'
          />
        )}
        {isSuperAdmin && hasPermission(ROLE_ENUM.SETTING_VIEW, userPermissions) && (
          <AsideMenuItem
            to='/apps/settings'
            icon='/media/icons/duotune/coding/cod001.svg'
            title='Manage Settings'
            fontIcon='bi-layers'
          />
        )}
      </AsideMenuItemWithSub>
      {hasPermission(ROLE_ENUM.VOUCHER_VIEW, userPermissions) && (
        <AsideMenuItem
          to='/apps/vouchers'
          icon='/media/icons/duotune/ecommerce/ecm003.svg'
          title='Manage Voucher'
          fontIcon='bi-layers'
        />
      )}
      {hasPermission(ROLE_ENUM.PROMO_VIEW, userPermissions) && (
        <AsideMenuItem
          to='/apps/manage-promotion'
          icon='/media/icons/duotune/ecommerce/ecm011.svg'
          title='Manage Promotion'
          fontIcon='bi-layers'
        />
      )}
      {hasPermission(ROLE_ENUM.PRODUCT_VIEW, userPermissions) && (
        <AsideMenuItem
          to='/apps/products'
          icon='/media/icons/duotune/medicine/med002.svg'
          title='Manage Produk'
          fontIcon='bi-layers'
        />
      )}
      {isSuperAdmin && (
        <AsideMenuItem
          to='/apps/users'
          icon='/media/icons/duotune/communication/com014.svg'
          title='Manage User Admin'
          fontIcon='bi-layers'
        />
      )}

      {isSuperAdmin && (
        <AsideMenuItem
          to='/apps/roles'
          icon='/media/icons/duotune/communication/com014.svg'
          title='Manage Role'
          fontIcon='bi-layers'
        />
      )}

      {hasPermission(ROLE_ENUM.PATIENT_VIEW, userPermissions) && (
        <AsideMenuItem
          to='/apps/patient-management'
          icon='/media/icons/duotune/communication/com014.svg'
          title='Manage Pasien'
          fontIcon='bi-layers'
        />
      )}
      {hasPermission(ROLE_ENUM.ACTIVITY_LOG_VIEW, userPermissions) && isSuperAdmin && (
        <AsideMenuItem
          to='/apps/activity-log'
          icon='/media/icons/duotune/communication/com014.svg'
          title='Log Aktivitas'
          fontIcon='bi-layers'
        />
      )}
      {hasPermission(ROLE_ENUM.HOMECARE_SCHEDULE_VIEW, userPermissions) && (
        <AsideMenuItem
          to='/apps/schedule-homecare'
          icon='/media/icons/duotune/general/gen051.svg'
          title='Jadwal Homecare'
        />
      )}
    </>
  )
}
