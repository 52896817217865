import {AxiosResponse} from 'axios'
import {Dispatch, SetStateAction} from 'react'
import {UserDataTable} from '../../../app/modules/apps/users/core/_models'

export type ID = undefined | null | number

export type PaginationState = {
  page: number
  limit: number
  total?: number
}

export type OrderDirection = 'asc' | 'desc' | string

export type SortState = {
  order?: OrderDirection
  orderBy?: string
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export interface MetaResponse {
  limit: 10 | 30 | 50 | 100
  order?: string
  orderBy?: string
  page: number
  total: number
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
  message?: string
  success?: boolean
  meta?: MetaResponse
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: AxiosResponse<Response<T>>
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  showChangePasswordModal: boolean
  setShowChangePasswordModal: Dispatch<SetStateAction<boolean>>
  selectedUser: UserDataTable | null
  setSelectedUser: Dispatch<SetStateAction<UserDataTable | null>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  showChangePasswordModal: false,
  setShowChangePasswordModal: () => {},
  selectedUser: null,
  setSelectedUser: () => {},
}
