export enum ROLE_ENUM {
  UPLOAD_IMAGE = 'upload.image',
  UPLOAD_DOCUMENT = 'upload.document',
  UPLOAD_VIDEO = 'upload.video',
  UPLOAD_ANY = 'upload.any',
  USER_CREATE = 'user.create',
  USER_VIEW = 'user.view',
  USER_UPDATE = 'user.update',
  USER_DELETE = 'user.delete',
  PATIENT_CREATE = 'patient.create',
  PATIENT_VIEW = 'patient.view',
  PATIENT_UPDATE = 'patient.update',
  PATIENT_DELETE = 'patient.delete',
  BRANCH_CREATE = 'branch.create',
  BRANCH_VIEW = 'branch.view',
  BRANCH_UPDATE = 'branch.update',
  BRANCH_DELETE = 'branch.delete',
  PRODUCT_CREATE = 'product.create',
  PRODUCT_VIEW = 'product.view',
  PRODUCT_UPDATE = 'product.update',
  PRODUCT_DELETE = 'product.delete',
  PRODUCTPRICE_CREATE = 'productprice.create',
  PRODUCTPRICE_VIEW = 'productprice.view',
  PRODUCTPRICE_UPDATE = 'productprice.update',
  PRODUCTPRICE_DELETE = 'productprice.delete',
  VOUCHER_CREATE = 'voucher.create',
  VOUCHER_VIEW = 'voucher.view',
  VOUCHER_UPDATE = 'voucher.update',
  VOUCHER_DELETE = 'voucher.delete',
  MASTER_LOCATION_CREATE = 'master-location.create',
  MASTER_LOCATION_VIEW = 'master-location.view',
  MASTER_LOCATION_UPDATE = 'master-location.update',
  MASTER_LOCATION_DELETE = 'master-location.delete',
  CHECKUP_CREATE = 'checkup.create',
  CHECKUP_VIEW = 'checkup.view',
  CHECKUP_UPDATE = 'checkup.update',
  CHECKUP_DELETE = 'checkup.delete',
  REQUIREMENT_CREATE = 'requirement.create',
  REQUIREMENT_VIEW = 'requirement.view',
  REQUIREMENT_UPDATE = 'requirement.update',
  REQUIREMENT_DELETE = 'requirement.delete',
  PAGE_CREATE = 'page.create',
  PAGE_VIEW = 'page.view',
  PAGE_UPDATE = 'page.update',
  PAGE_DELETE = 'page.delete',
  PROMO_CREATE = 'promo.create',
  PROMO_VIEW = 'promo.view',
  PROMO_UPDATE = 'promo.update',
  PROMO_DELETE = 'promo.delete',
  BANNER_CREATE = 'banner.create',
  BANNER_VIEW = 'banner.view',
  BANNER_UPDATE = 'banner.update',
  BANNER_DELETE = 'banner.delete',
  SETTING_CREATE = 'setting.create',
  SETTING_VIEW = 'setting.view',
  SETTING_UPDATE = 'setting.update',
  SETTING_DELETE = 'setting.delete',
  CATEGORY_CREATE = 'category.create',
  CATEGORY_VIEW = 'category.view',
  CATEGORY_UPDATE = 'category.update',
  CATEGORY_DELETE = 'category.delete',
  DAY_OFF_CREATE = 'day-off.create',
  DAY_OFF_VIEW = 'day-off.view',
  DAY_OFF_UPDATE = 'day-off.update',
  DAY_OFF_DELETE = 'day-off.delete',
  ACTIVITY_LOG_VIEW = 'activity-log.view',
  CHECKUP_MONITOR_CANCEL = 'checkup-monitor.cancel',
  CHECKUP_MONITOR_VIEW = 'checkup-monitor.view',
  CHECKUP_MONITOR_CONFIRM = 'checkup-monitor.confirm',
  CHECKUP_MONITOR_CHANGE_PAYMENT_STATUS = 'checkup-monitor.change-payment-status',
  CHECKUP_MONITOR_CHANGE_STATUS_SAMPLE = 'checkup-monitor.change-status-sample',
  CHECKUP_MONITOR_CHECK_MEDICAL_REPORT = 'checkup-monitor.check-medical-report',
  CHECKUP_DATA_CANCEL = 'checkup-data.cancel',
  CHECKUP_DATA_VIEW = 'checkup-data.view',
  CHECKUP_DATA_CHANGE_PAYMENT_STATUS = 'checkup-data.change-payment-status',
  CHECKUP_DATA_CHANGE_STATUS = 'checkup-data.change-status',
  CHECKUP_DATA_CHANGE_MRN = 'checkup-data.change-mrn',
  CHECKUP_DATA_CHANGE_VISIT = 'checkup-data.change-visit',
  CHECKUP_DATA_CHANGE_STATUS_SAMPLE = 'checkup-data.change-status-sample',
  CHECKUP_DATA_CHECK_MEDICAL_REPORT = 'checkup-data.check-medical-report',
  SCAN_CHECKUP_QR = 'checkup-scan.qr',
  HOMECARE_SCHEDULE_VIEW = 'homecare-schedule.view',
}

export const actionRoleText = {
  create: 'Buat',
  view: 'Lihat Detail',
  update: 'Perbarui',
  delete: 'Hapus',
}
